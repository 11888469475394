export const APP_ROUTES = {
  home: "/",
  extensionGeneral: "/general",
  extensionJobScoreDetails: "/job-score-details",
  extensionUserProfile: "/user-profile",
  extensionAnalytics: "/analytics",
  extensionDeals: "/deals",
  extensionCRM: "/crm",
  extensionPrompts: "/prompts",
  extensionPromptCreate: "/prompts/create",
  extensionPromptEdit: "/prompts/edit",
  extensionSettings: "/settings",
  coverLetter: "/cover-letter",
  proposalSubmitting: "/proposal-submitting",
  profileConnect: "/profile-connect",
  optionPage: "/option",
  aiAssistant: "/ai-assistant",
  news: "/news",
  // frontend routes
  signIn: "/login",
  signUp: "/sign-up",
  dashboard: "/dashboard",
  billing: "/billing",
  exSignUp: "/ex-sign-out",
  settings: "/settings",
  toolsAndApps: "/tools-and-apps",
};
