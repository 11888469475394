import React from "react";
import { Routes, Route } from "react-router-dom";
import { APP_ROUTES } from "./data-access";
import { NothingFound } from "./feature";
import {
  AnalyticsFrame,
  CRMFrame,
  CoverLetterFrame,
  DealsFrame,
  GeneralFrame,
  JobScoreDetailsFrame,
  ProfileConnectFrame,
  PromptEditFrame,
  PromptsFrame,
  SettingsFrame,
  UserProfileFrame,
} from "./frames";
import { ProposalSubmittingFrame } from "./frames/proposal-submitting-frame";
import { OptionFrame } from "./frames/option-frame";
import { AIAssistantFrame } from "./frames/ai-assistant-frame";
import { NewsFrame } from "./frames/news-frame";

function App(): JSX.Element {
  return (
    <div className="main-container">
      <Routes>
        <Route path={APP_ROUTES.extensionGeneral} element={<GeneralFrame />} />
        <Route
          path={APP_ROUTES.extensionJobScoreDetails}
          element={<JobScoreDetailsFrame />}
        />
        <Route
          path={APP_ROUTES.extensionUserProfile}
          element={<UserProfileFrame />}
        />
        <Route
          path={APP_ROUTES.extensionAnalytics}
          element={<AnalyticsFrame />}
        />
        <Route path={APP_ROUTES.extensionDeals} element={<DealsFrame />} />
        <Route path={APP_ROUTES.extensionCRM} element={<CRMFrame />} />
        <Route path={APP_ROUTES.extensionPrompts} element={<PromptsFrame />} />
        <Route
          path={APP_ROUTES.extensionPromptCreate}
          element={<PromptEditFrame />}
        />
        <Route
          path={APP_ROUTES.extensionPromptEdit}
          element={<PromptEditFrame />}
        />
        <Route
          path={APP_ROUTES.extensionSettings}
          element={<SettingsFrame />}
        />
        <Route path={APP_ROUTES.coverLetter} element={<CoverLetterFrame />} />
        <Route
          path={APP_ROUTES.proposalSubmitting}
          element={<ProposalSubmittingFrame />}
        />
        <Route path={APP_ROUTES.aiAssistant} element={<AIAssistantFrame />} />
        <Route path={APP_ROUTES.optionPage} element={<OptionFrame />} />
        <Route path={APP_ROUTES.news} element={<NewsFrame />} />
        <Route
          path={APP_ROUTES.profileConnect}
          element={<ProfileConnectFrame />}
        />
        <Route path="*" element={<NothingFound />} />
      </Routes>
    </div>
  );
}

export default App;
