import moment from "moment";

export const getAIAssistantData = (res: any): any => {
  const dataObject = res.data;

  const reviews = dataObject.buyer.workHistory || [];
  const job = dataObject?.opening?.job || {};

  const skills = job.sandsData.additionalSkills || [];
  const skills1 = job.sandsData.ontologySkills || [];
  const client = dataObject?.buyer || null;
  const clientActivity = job?.clientActivity || null;

  const budgetResponse = getBudget(job);
  const level = job.contractorTier;
  const titles = reviews.map((review: any) => review?.jobInfo?.title);
  const comments = reviews.map(
    (review: any) => review?.feedbackToClient?.comment
  );

  const jobInfo = {
    title: job?.info?.title || "", // Job Title:
    description: job?.description || "", // Job Description:
    budget: budgetResponse?.budget || "", // Budget:
    paymentType: budgetResponse?.paymentType || "", // Payment type:
    experienceLevel: level, // Experience Level:
    jobCategory:
      job?.categoryGroup?.name && job?.category?.name
        ? `${job?.categoryGroup?.name}, ${job?.category?.name}`
        : "", // Job category:
    requiredSkills: [
      ...skills.map((skill: any) => skill.prefLabel),
      ...skills1.map((skill: any) => skill.prefLabel),
    ].filter((sk) => sk), // Required Skills:
    proposals: clientActivity?.totalApplicants || "0", // Number of Proposals:
    jobPosted: job.postedOn ? moment(job.postedOn).fromNow() : "None", // Date Posted:
    paymentVerification: client?.isPaymentMethodVerified || false, // Client Payment Verification:
    memberSince: client?.info?.company?.contractDate
      ? moment(client?.info.company.contractDate).format("MMM DD, YYYY")
      : moment().format("MMM DD, YYYY"), // Client Join Date(Member since):
    totalReviews: client?.info?.stats?.feedbackCount || "0", // Number of Client Reviews:
    hireRate:
      client?.info?.jobs?.postedCount && client?.info?.stats?.totalJobsWithHires
        ? `${(
            (client?.info.stats.totalJobsWithHires /
              client?.info.jobs.postedCount) *
            100
          ).toFixed(0)}%`
        : "0%", // Hire Rate:
    totalSpent: client?.info?.stats?.totalCharges?.amount
      ? `$${client?.info?.stats?.totalCharges?.amount}`
      : "$0", // Total Spent:
    invites: clientActivity?.invitationsSent || "0", // Invites:
    interviews: clientActivity?.totalInvitedToInterview || "0", // Interviewing:
    hired: clientActivity?.totalHired || "0", // Hired:
    rating: client?.info?.stats?.score || "0", // Client Rating:
    reviewsTitles: titles.filter((title: string) => title) || [], // List of job titles:
    reviewsFeedback: comments.filter((comment: string) => comment) || [], // List of text reviews:
  };

  const jobId = job?.info?.ciphertext;
  settingJobData(jobId, jobInfo);

  return { jobInfo, jobId };
};

export function gettingJobAIText(jobId: string) {
  const storage = localStorage.getItem("ai-jobs");
  const jobs = JSON.parse(storage || "{}");
  return jobs[jobId] || null;
}

export function settingJobAIText(jobId: string, data: any) {
  const storage = localStorage.getItem("ai-jobs");
  const jobs = JSON.parse(storage || "{}");

  jobs[jobId] = data;

  localStorage.setItem("ai-jobs", JSON.stringify(jobs));
}

export function gettingJobData(jobId: string) {
  const storage = localStorage.getItem("up-jobs");
  const jobs = JSON.parse(storage || "{}");
  return jobs[jobId] || null;
}

export function settingJobData(jobId: string, data: any) {
  const storage = localStorage.getItem("up-jobs");
  const jobs = JSON.parse(storage || "{}");
  jobs[jobId] = data;
  localStorage.setItem("up-jobs", JSON.stringify(jobs));
}

function getBudget(job: any): { budget: string; paymentType: string } {
  let response = { budget: "", paymentType: "" };

  const min = job.extendedBudgetInfo.hourlyBudgetMin;
  const max = job.extendedBudgetInfo.hourlyBudgetMax;

  if (min || max) {
    if (min && max) {
      response.budget = `$${min} to $${max}`;
    } else if (min) {
      response.budget = `$${min}`;
    } else {
      response.budget = `$${max}`;
    }
    response.paymentType = "Hourly-price";
  } else {
    response = {
      budget: `$${job.budget.amount}`,
      paymentType: "Fixed-price",
    };
  }

  return response;
}
