import React, { useEffect, useState } from "react";
import "./general-frame.scss";

import { ExtensionUserSubscription } from "../../components";
import { APP_ROUTES, IUser } from "../../data-access";
import { ExtensionAnalytics } from "../../components/extension-analytics";
import { extensionApi } from "../../api";
import { Loader } from "../../ui/loader";

const baseUrl = process.env.REACT_APP_URL;

export const GeneralFrame = (): JSX.Element => {
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  useEffect(() => {
    setIsLoading(true);
    if (refreshToken) {
      extensionApi
        .getMe(refreshToken)
        .then((response: { data: IUser }) => {
          setCurrentUser(response.data);
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [refreshToken]);

  return (
    <div className="extension-container">
      <div className="extension-header">
        <img src="/logo-white.png" />

        <div className="extension-header__image">
          <img
            className={
              currentUser?.upwork_profile?.portrait?.bigPortrait
                ? "profile-img"
                : ""
            }
            src={
              currentUser?.upwork_profile?.portrait?.bigPortrait ||
              "/user_profile_avatar.svg"
            }
          />
        </div>
      </div>

      {isLoading && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {!isLoading && (
        <h3 className="extension-title">
          {currentUser?.upwork_profile?.username || "Hello User"}
        </h3>
      )}

      {!isLoading && currentUser && (
        <div className="extension-content">
          <a
            className="extension-btn primary-ex-btn"
            target="_blank"
            href={`https://www.upwork.com/freelancers/`}
          >
            {currentUser?.upwork_profile?.username
              ? "Open Profile"
              : "Upwork Login"}
          </a>

          <a
            className="extension-btn secondary-ex-btn m-minus"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.dashboard}`}
          >
            <img src="/statistic-icon.svg" />
            See full analytics
          </a>

          <ExtensionUserSubscription user={currentUser} />

          <div className="extension-profile-stats-content">
            <span className="extension-sub-text">
              Statistics for current month:
            </span>
            <ExtensionAnalytics
              isFullAnalytics={false}
              isVisibleButton={false}
              refreshToken={refreshToken}
              currentUser={currentUser}
            />
          </div>
        </div>
      )}

      {!isLoading && !currentUser && (
        <div className="extension-content g-12">
          <a
            className="extension-btn primary-ex-btn m-b-32"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.signIn}`}
          >
            Login in Upwex
          </a>
          <span className="extension-sub-text">Don't have an account?</span>
          <a
            className="extension-btn secondary-ex-btn"
            target="_blank"
            href={`${baseUrl}${APP_ROUTES.signUp}`}
          >
            Sign Up
          </a>
        </div>
      )}
    </div>
  );
};
