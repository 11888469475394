import React, { FocusEvent, useEffect, useState } from "react";
import "./profile-connect-frame.scss";

import { Loader } from "../../ui/loader";
import { notify, postExtensionMessage } from "../../util";
import { PostMessageType } from "../../data-access";
import { FaUndo } from "react-icons/fa";
import { ExtensionTableItem } from "../../components";
import { extensionApi } from "../../api";
import { FormButton, FormInput, FormSelect } from "../../ui";
import { Controller, useForm } from "react-hook-form";

export const ProfileConnectFrame = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedProfile, setSelectedProfile] = useState<any>(null);
  const [manuallyProfileId, setManuallyProfileId] = useState<any>("");
  const [upworkProfiles, setUpworkProfiles] = useState<any[]>([]);
  const [profileSelectOptions, setProfileSelectOptions] = useState<
    { value: string; label: string }[]
  >([]);

  const queryParams = new URLSearchParams(window.location.search);
  const upwexUserStatParam = queryParams.get("upwexUserStat");
  const user = JSON.parse(upwexUserStatParam || "{}");
  const refreshToken = user.rt_score;

  useEffect(() => {
    const options = upworkProfiles.map((prompt: any) => {
      return {
        value: prompt.id,
        label: prompt.username,
      };
    });
    setProfileSelectOptions(options);
  }, [upworkProfiles]);

  useEffect(() => {
    setIsLoading(true);

    extensionApi
      .getUpworkProfiles(refreshToken)
      .then((response: { data: any }) => {
        const profiles = response.data;
        setUpworkProfiles(profiles);

        const selectedProfile = profiles.filter((p: any) => p.is_selected);
        setSelectedProfile(selectedProfile[0]);
      })
      .finally(() => setIsLoading(false));
  }, [refreshToken]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "send-profile-contented") {
        const user = event.data.data;
        if (manuallyProfileId) {
          user.userId = manuallyProfileId;
        }
        const findingProfile = upworkProfiles.filter(
          (p) => p.userId === user.userId
        );

        if (findingProfile && findingProfile.length && findingProfile[0].id) {
          updateUpworkProfile(user, findingProfile[0].id);
        } else {
          createUpworkProfile(user);
        }
        setManuallyProfileId("");
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [manuallyProfileId, upworkProfiles]);

  const createUpworkProfile = (profile: any) => {
    extensionApi
      .setUpworkProfile(refreshToken, profile)
      .then((response: { data: any }) => {
        const profiles = response.data.data;
        const selectedProfile = profiles.filter(
          (p: any) => p.userId === profile.userId
        );

        setUpworkProfiles(profiles);
        makeProfileSelected(selectedProfile[0].id);
      })
      .finally(() => setIsLoading(false));
  };

  const updateUpworkProfile = (profile: any, id: string) => {
    extensionApi
      .updateUpworkProfile(refreshToken, id, profile)
      .then((response: { data: any }) => {
        const profiles = response.data.data;
        const selectedProfile = profiles.filter(
          (p: any) => p.userId === profile.userId
        );

        setUpworkProfiles(profiles);
        makeProfileSelected(selectedProfile[0].id);
      })
      .finally(() => setIsLoading(false));
  };

  const handleDel = () => {
    extensionApi
      .deleteUpworkProfile(refreshToken, "42")
      .then((response: { data: any }) => {})
      .finally(() => setIsLoading(false));
  };

  const makeProfileSelected = (id: string) => {
    if (id) {
      setIsLoading(true);
      extensionApi
        .makeUpworkProfileActive(refreshToken, id)
        .then((response: { data: any }) => {
          const profiles = response.data.data;
          const selectedProfile = profiles.filter((p: any) => p.is_selected);

          setSelectedProfile(selectedProfile[0]);
          postExtensionMessage(
            "set-selected-up-user" as PostMessageType,
            selectedProfile[0]
          );
        })
        .finally(() => setIsLoading(false));
    }
  };

  const getDescription = (description: string) => {
    return JSON.parse(description).replace(/\n/g, "<br>");
  };

  const handleSyncProfile = () => {
    setIsLoading(true);
    postExtensionMessage("connect-profile" as PostMessageType, null);
  };

  const handleManuallySyncProfile = (data: any) => {
    if (data.link.indexOf("www.upwork.com/freelancers/") < 0) {
      notify("error", "Upwork Profile link is not correct. Please, try again.");
    } else {
      const ids = data.link.split("freelancers/");
      const idProfile = ids[1].split("/");
      setManuallyProfileId(idProfile[0]);
      setIsLoading(true);
      postExtensionMessage("connect-profile" as PostMessageType, data);

      reset({ link: "" });
    }
  };

  const form = useForm<any>({
    mode: "onBlur",
    defaultValues: {
      link: "",
    },
  });
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { isValid },
  } = form;

  return (
    <div className="extension-container filled">
      <div className="extension-header">
        <img src="/logo-white.png" alt="Logo" />

        <div className="extension-header__image">
          <img
            className={
              selectedProfile?.portrait?.bigPortrait ? "profile-img" : ""
            }
            src={
              selectedProfile?.portrait?.bigPortrait ||
              "/user_profile_avatar.svg"
            }
            alt="profile"
          />
        </div>
      </div>

      {isLoading && (
        <div className="frame-loader">
          <Loader />
        </div>
      )}

      {!isLoading && (
        <>
          <h3 className="extension-title">Upwork Connected Profiles</h3>

          <div className="extension-content g-12">
            <button
              className="extension-btn primary-ex-btn m-b-12"
              onClick={handleSyncProfile}
            >
              Sync Current Profile{" "}
              {selectedProfile?.username && upworkProfiles.length && <FaUndo />}
            </button>

            <p className="ex-title">Mannually Sync (for agency)</p>
            <form
              className="invite-member-form m-b-12"
              onSubmit={handleSubmit(handleManuallySyncProfile)}
            >
              <Controller
                control={control}
                name="link"
                render={({ field: { name, onBlur, value, ref } }) => (
                  <FormInput
                    ref={ref}
                    placeholder="Please paste Upwork Profile link here..."
                    name={name}
                    className="m-b-12"
                    value={value}
                    onBlur={onBlur}
                    onChange={(e: FocusEvent<HTMLInputElement>) =>
                      setValue("link", e?.target?.value, {
                        shouldDirty: true,
                        shouldValidate: true,
                      })
                    }
                  />
                )}
              />

              <FormButton
                className="extension-form-button primary-ex-btn"
                loading={isLoading}
                disabled={!isValid || isLoading}
                htmlType="submit"
              >
                Manually Sync Profile
              </FormButton>
            </form>

            <h3 className="extension-subtitle m-b-8">Selected Profile</h3>
            {upworkProfiles.length > 0 && (
              <FormSelect
                className="m-b-8"
                value={selectedProfile?.id}
                placeholder="None"
                options={profileSelectOptions}
                onChange={(id) => makeProfileSelected(id)}
              />
            )}

            {/* <button
              className="extension-btn primary-ex-btn m-b-12"
              onClick={handleDel}
            >
              Delete
            </button> */}

            {selectedProfile?.username && (
              <>
                <p className="ex-title">General Information</p>
                <div className="extension-block g-4 m-b-8">
                  <ExtensionTableItem
                    label="Username"
                    value={selectedProfile?.username}
                  />
                  <ExtensionTableItem
                    label="Country"
                    value={selectedProfile?.country}
                  />
                  <ExtensionTableItem
                    label="Availability"
                    value={selectedProfile?.availability}
                  />
                  <ExtensionTableItem
                    label="User ID"
                    value={`${selectedProfile?.userId}`}
                  />
                </div>

                <p className="ex-title">Profile Title</p>
                <p className="extension-profile-info">
                  {selectedProfile?.profileTitle}
                </p>

                <p className="ex-title">Profile Description</p>
                <div
                  className="extension-profile-info"
                  dangerouslySetInnerHTML={{
                    __html: getDescription(selectedProfile?.profileDescription),
                  }}
                ></div>

                <p className="ex-title">Skills</p>
                <div className="extension-profile-skills m-b-12">
                  {selectedProfile?.skills.map((skill: string) => (
                    <span>{skill}</span>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
